import styles from './anlagen.module.scss';
import Layout from 'components/layout';
import Wrapper from 'components/Wrapper/Wrapper';

import StickyBar from 'components/StickyBar/StickyBar';
import api from 'api';
import useAuthStore from 'store/useAuth';
import React, { Fragment, useEffect, useState } from 'react';
import Container from '@wienenergiegithub/ui-next/src/common/components/Container/Container';
import InputField from '@wienenergiegithub/ui-next/src/common/components/InputField/InputField';
import Button from '@wienenergiegithub/ui-next/src/common/components/Button/Button';
import Checkbox from '@wienenergiegithub/ui-next/src/common/components/Checkbox/Checkbox';
import Content from '@wienenergiegithub/ui-next/src/common/components/Content/Content';
import Card from '@wienenergiegithub/ui-next/src/common/components/Card/Card';
import { useNotification } from '@wienenergiegithub/layout/src/common/modules/Notification/Context';
import Table from '@wienenergiegithub/ui-next/src/common/components/Table/Table';
import { Link } from 'gatsby';

import {
  unstable_useFormState as useFormState,
  unstable_Form as Form,
  unstable_FormCheckbox as FormCheckbox,
  unstable_FormSubmitButton as FormSubmitButton,
  unstable_FormMessage as FormMessage,
} from 'reakit/Form';

const GlobalSettings = ({ userCanEdit, configuration, form }) => {
  return (
    <Card size="xl" className={styles.globalSettingsWrapper}>
      <div>
        <strong>Definition Global Brennwerte</strong>
      </div>
      <div className={styles.col3}>
        <strong>Lagerbar</strong>
      </div>
      {configuration.map(
        ({ display_name: displayName, internal_name: internalName }, index) => (
          <Fragment key={internalName}>
            <Content className={styles.rowLabel}>
              {internalName} - {displayName}
            </Content>

            <InputField
              name={['configuration', index, 'value']}
              type="number"
              labelHidden
              label=""
              disabled={!userCanEdit}
              {...form}
              noMessage
            />
            <FormCheckbox
              as={Checkbox}
              name={['configuration', index, 'is_storable']}
              className={styles.formCheckbox}
              disabled={!userCanEdit}
              {...form}
            />
            <FormMessage
              {...form}
              name={['configuration', index, 'value']}
              className={styles.errorMessage}
            />
          </Fragment>
        ),
      )}
    </Card>
  );
};

const AnlagenPage = () => {
  const [facilites, setFacilites] = useState(null);
  const [configuration, setConfiguration] = useState(null);
  const notifications = useNotification();
  const id = (Math.random() + 1).toString(36).substring(7);
  const userCan = useAuthStore(state => state.userCan);

  const userCanEdit = userCan('edit facility');

  const form = useFormState({
    values: {
      configuration,
    },
    onValidate: values => {
      const errors = {};
      values.configuration.forEach((value, i) => {
        if (!value.value) {
          if (!errors.configuration) {
            errors.configuration = [];
          }
          if (!errors.configuration[i]) {
            errors.configuration[i] = {};
          }
          errors.configuration[i].value = 'Feld muss ausgefüllt werden';
        }
      });
      if (Object.keys(errors).length) {
        throw errors;
      }
    },
    onSubmit: async values => {
      try {
        await api.post('/backend/api/configurations', values.configuration);
        notifications.add({
          title: 'Allgemeine Anlagenkonfiguration gespeichert',
          icon: 'check',
          id,
          type: 'success',
          handleClose: () => {
            // eslint-disable-next-line no-console
            console.log('close');
          },
        });
        setTimeout(() => {
          notifications.remove(id);
        }, 5000);
      } catch (error) {
        notifications.add({
          title: 'Es ist ein Fehler aufgetreten',
          details: 'Bitte versuchen Sie es später erneut',
          icon: 'exclamation',
          id,
          type: 'danger',
        });
        setTimeout(() => {
          notifications.remove(id);
        }, 5000);
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
  });
  useEffect(() => {
    const doRequests = () => {
      try {
        const promiseFacility = api.get('/backend/api/facilities');
        const promiseConfiguration = api.get('/backend/api/configurations/');

        Promise.all([promiseFacility, promiseConfiguration]).then(responses => {
          setFacilites(responses[0]?.data?.data);
          setConfiguration(responses[1]?.data?.data);
          form.update('configuration', responses[1]?.data?.data);
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };
    doRequests();
  }, []);

  return (
    <Wrapper>
      <Layout title="Anlagen">
        <Form {...form}>
          {userCanEdit && (
            <StickyBar>
              <div className={styles.actions}>
                <FormSubmitButton
                  as={Button}
                  className={styles.submit}
                  {...form}
                >
                  Speichern
                </FormSubmitButton>
              </div>
            </StickyBar>
          )}
          {form.values.configuration && (
            <Container size="md">
              <GlobalSettings
                userCanEdit={userCanEdit}
                form={form}
                configuration={configuration}
              />
            </Container>
          )}

          {facilites && (
            <Container size="md" className={styles.containerLayout}>
              <Table withParser={false}>
                <table>
                  <thead>
                    <tr>
                      <th>
                        <strong>Name</strong>
                      </th>

                      {userCanEdit && <th />}
                    </tr>
                  </thead>
                  <tbody>
                    {facilites?.map(facility => (
                      <tr key={facility.id}>
                        <td>{facility.name}</td>
                        {userCanEdit && (
                          <td>
                            <Button
                              as={Link}
                              variant="link"
                              to={`/anlagendaten?facility=${facility.id}`}
                            >
                              Bearbeiten
                            </Button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Table>
            </Container>
          )}
        </Form>
      </Layout>
    </Wrapper>
  );
};

export default AnlagenPage;
